import * as React from 'react'
import loadable from '@loadable/component'
import { StaticImage } from 'gatsby-plugin-image'
import './styles.scss'

const FieldHelp = loadable(() => import('/src/components/Form/FieldHelp'))
const FieldSet = loadable(() => import('/src/components/Form/FieldSet'))
const FieldSpacer = loadable(() => import('/src/components/Form/FieldSpacer'))
const Radio = loadable(() => import('/src/components/Form/Radio'))

const StepPartner = ({ isSubmitting, isSubmitted }) => {
    return (
        <FieldSet legend={`I'd like quotes for:`} groupName={'partner'} required>
            <FieldSpacer grow={'third'}>
                <Radio
                    label={'Just Me'}
                    groupName={'partner'}
                    name={'partnerNo'}
                    value={'NO'}
                    isSubmitting={isSubmitting}
                    isSubmitted={isSubmitted}
                />
            </FieldSpacer>

            <FieldSpacer grow={'third'} className={'c-field-voucher'}>
                <Radio
                    label={'Me & My Partner'}
                    groupName={'partner'}
                    name={'partnerYes'}
                    value={'YES'}
                    isSubmitting={isSubmitting}
                    isSubmitted={isSubmitted}
                />
                <StaticImage
                    src={'../../../../images/gift-voucher.png'}
                    alt={'Gift voucher'}
                    className={'c-field-voucher__voucher'}
                    width={60}
                />
                <FieldHelp>Purchase policies for two people and receive a free £40 gift voucher!</FieldHelp>
            </FieldSpacer>
        </FieldSet>
    )
}

export default StepPartner
